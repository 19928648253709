import React, { ReactElement, ReactNode, useEffect } from 'react'

import { VIEWLIO_ENV } from '@viewlio/config/viewlioConfig'
import type { MetaEntry } from '@viewlio/types/contentful'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { AppContextProvider } from '@consumer/providers'
import { IntlProvider } from 'react-intl'

import { AppHead } from '@consumer/components/common/AppHead'
import { AppScripts } from '@consumer/components/common/AppScripts'
import { ImpersonationNotice } from '@consumer/components/common/ImpersonationNotice'
import { ErrorBoundary } from '@consumer/lib/bugsnag'
import { LayoutContentfulEntries } from '@consumer/lib/contentful/fetchLayoutContentfulEntries'
import {
  AppWrapper,
  CreateStoresConfig,
  useGlobalStore,
} from '@consumer/stores'
import { datadogRumScript, datadogLogsScript, accessibeScript } from '@consumer/utils/script'
import { Translations } from '@consumer/utils/translations'

import '@consumer/styles/styles.scss'

type PageProps = {
  __viewlioInitialStoreData: CreateStoresConfig
  alternateLangs?: Record<string, string>
  layoutContentfulEntries: LayoutContentfulEntries
  metaEntry: MetaEntry
  personalizedEntriesIds?: string[]
  statusCode?: number
  translations: Translations
}

export type PageWithGetLayout<T = AppProps<PageProps>['Component']> = T & {
  getLayout?: (page: ReactElement, pageProps: Record<string, any>) => ReactNode
}

export type ViewlioAppProps = AppProps<PageProps> & {
  Component: PageWithGetLayout
}

const Viewlio: React.FC<ViewlioAppProps> = ({
  Component,
  pageProps,
}) => {
  if (pageProps?.statusCode >= 400) {
    return <Component {...pageProps} />
  }

  const {
    isFallback,
  } = useRouter()

  if (isFallback) {
    return null
  }

  const {
    alternateLangs,
    metaEntry,
    translations,
  } = pageProps as PageProps

  const {
    fetchShopper,
    locale,
    juulioStore: {
      code,
    },
  } = useGlobalStore()

  useEffect(() => {
    fetchShopper()
  }, [])

  const getLayout = Component.getLayout || ((page, _pageProps) => page)

  return (
    <IntlProvider
      messages={translations}
      locale={locale}
      textComponent='span'
    >
      <ErrorBoundary navigationEntry={
        pageProps.layoutContentfulEntries.navigationEntry
      }>
        <AppContextProvider>
          <div className={code}>
            <AppHead
              contentfulData={metaEntry}
              alternateLangs={alternateLangs}
            />
            <AppScripts />
            {getLayout(<Component {...pageProps} />, pageProps)}
            <ImpersonationNotice />
          </div>
        </AppContextProvider>
      </ErrorBoundary>
    </IntlProvider>
  )
}

const ViewlioWithStores: React.FC<ViewlioAppProps> = props => (
  <>
    <AppWrapper {...props}>
      <Viewlio {...props} />
    </AppWrapper>
    <script dangerouslySetInnerHTML={{
      __html: datadogRumScript,
    }} />
    <script dangerouslySetInnerHTML={{
      __html: datadogLogsScript,
    }} />
    {
      VIEWLIO_ENV === 'staging' && (
        <script dangerouslySetInnerHTML={{
          __html: accessibeScript,
        }} />
      )
    }
  </>
)

export default ViewlioWithStores
