import { StoreType } from '@viewlio/config/types'
import { viewlioConfig, VIEWLIO_ENV } from '@viewlio/config/viewlioConfig'
import { paths } from '@viewlio/config/viewlioConfig/paths'

import Jsona from 'jsona'

import { bugsnagClient } from 'lib/bugsnag'
import { useGlobalStore } from 'stores'
import { getBugsnagError } from 'utils/bugsnag/getBugsnagError'
import { camelizeKeys } from 'utils/caseConversion'
import { getStoreByTypeAndLocale } from 'utils/locales/getStoreByTypeAndLocale'
import { parse, stringify } from 'utils/urls/querystring'

const { apiKeys } = viewlioConfig

const defaultLocale = () =>
  useGlobalStore.getState().locale

const defaultStoreType = () =>
  useGlobalStore.getState().storeType

type RequestWithoutBody = {
  body?: never
  method?: 'GET'
}

type RequestWithBody = {
  body?: any
  method: 'PUT' | 'POST' | 'PATCH' | 'DELETE'
}

type ErrorHandlers = Record<number | 'fatalError', (errorResponse: Response, throwError?: boolean) => void>

export const parseJSONAPIPayload = <T>(payload) => {
  if (payload?.data) {
    return new Jsona().deserialize(payload) as Promise<T>
  } else {
    return null
  }
}

const handleError = (errorResponse: Response, throwError = false) => {
  const handleThrowError = async (response: Response) => {
    // when server returns 500 or fails without a response, return generic error
    if (response.status >= 500 || typeof response.json === 'undefined') {
      throw {
        error: 'common.errors.default',
        statusCode: response.status,
      }
    }

    const responseJSON = await response.json()

    if (responseJSON?.errors?.[0]?.detail) {
      throw {
        error: responseJSON.errors[0].detail,
        statusCode: response.status,
      }
    }

    throw {
      ...responseJSON,
      statusCode: response.status,
    }
  }

  return throwError ?
    handleThrowError(errorResponse) :
    errorResponse as Response
}

const defaultErrorHandlers = {
  401: (errorResponse: Response, throwError = false) => {
    window.location.href = paths.signin

    return handleError(errorResponse, throwError)
  },
  fatalError: (errorResponse: Response, throwError = false) => {
    // eslint-disable-next-line max-len
    // https://docs.bugsnag.com/platforms/javascript/reporting-handled-errors/#customizing-diagnostic-data
    bugsnagClient.notify(
      getBugsnagError(errorResponse),
      (event) => {
        event.severity = 'error'
      },
    )

    return handleError(errorResponse, throwError)
  },
}

// Use the AWS WAF Integration fetch wrapper
// to automatically request and set a WAF token
// before every request.
const getFetchAPI = () => {
  if (typeof window !== 'undefined' && window.AwsWafIntegration?.fetch) {
    return window.AwsWafIntegration.fetch
  }

  return fetch
}

type APIRequestConfig = {
  autoCamelizeKeys?: boolean
  errorHandlers?: ErrorHandlers
  locale?: string
  relativePath: string
  signal?: AbortSignal | null
  storeType?: StoreType
  throwError?: boolean
} & (RequestWithoutBody | RequestWithBody)

export const fetchFromApi = async ({
  relativePath,
  method = 'GET',
  body = undefined,
  autoCamelizeKeys = true,
  locale = defaultLocale(),
  storeType = defaultStoreType(),
  throwError = false,
  errorHandlers = defaultErrorHandlers,
  signal = null,
}: APIRequestConfig): Promise<any> => {
  if (typeof locale !== 'string') {
    throw new Error('API request was called while locale is not properly set on global store')
  }

  const [_storeCode, store] = getStoreByTypeAndLocale(storeType, locale)
  const url = new URL(relativePath, store.baseUrl[VIEWLIO_ENV])

  const useAbsoluteUrl = typeof window === 'undefined' ||
    viewlioConfig.xhrAbsoluteUrlEnvironments.includes(VIEWLIO_ENV)

  const stringifyUrl = (url: URL) =>
    useAbsoluteUrl ? url.toString() : `${url.pathname}${url.search}`

  try {
    const query = parse(url.search)

    if (store.locales.length > 1) {
      query.locale = locale
    }

    url.search = stringify(query, { encodeValuesOnly: true })

    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Juulio-Skip-Csrf': 'Skip',
      'X-Requested-With': 'XMLHttpRequest',
    }

    if (typeof window === 'undefined') {
      headers['x-viewlio-edge-id'] = apiKeys?.cloudfrontEdgeId ?? undefined
      headers['X-Juul-Runtime'] = 'viewlio'
      headers['X-JUULCUSTOM'] = 'loadtest'
    }

    const fetch = getFetchAPI()

    const response = await fetch(
      stringifyUrl(url),
      {
        body: JSON.stringify(body),
        credentials: 'include',
        headers,
        method,
        signal: signal,
      },
    )

    if (response.ok) {
      const json = await response.json()
      return autoCamelizeKeys ? camelizeKeys(json) : json
    } else {
      throw response
    }

  } catch (errorResponse) {
    if (typeof window === 'undefined') {
      const errorLog = {
        body: null,
        headers: null,
        status: null,
        text: null,
        url: stringifyUrl(url),
        viewlioEnv: VIEWLIO_ENV,
      }

      if (errorResponse?.status) {
        errorLog.headers = errorResponse.headers
        errorLog.status = errorResponse.status
        errorLog.text = errorResponse.text()
      }

      // eslint-disable-next-line no-console
      console.log('\nJuulio API request error:', JSON.stringify(errorLog, null, 2))

      // In server environments, as we currently only do SSG (static) rendering,
      // we want to throw an unhandled exception for failed network requests, to
      // cause our build process (either initial build or regeneration) to exit
      // with a non-zero code. This will cause the build to fail, and will leave
      // previously cached content in place, until we have a successful build.
      throw new Error(errorResponse)
    } else {
      if (typeof errorResponse?.status === 'number') {
        if (errorResponse.status >= 500) {
          return errorHandlers['fatalError'](errorResponse, throwError)
        }

        if (errorHandlers[errorResponse.status]) {
          return errorHandlers[errorResponse.status](errorResponse, throwError)
        }
      }

      return handleError(errorResponse, throwError)
    }
  }
}
