export const paths = {
  account: '/account',
  app: '/app',
  forgotPassword: '/password/new',
  forgotPasswordEdit: '/password/edit',
  privacyPolicy: '/legal/privacy-policy',
  signin: '/signin',
  signout: '/signout',
  termsAndConditions: '/legal/terms',
  somethingWentWrong: '/500'
}
