import { JuulStore } from '@viewlio/types'
import { CsrfTokenResponse, ShopperResponse } from '@viewlio/types/api/juulio'
import { ProductCatalogueRaw } from '@viewlio/types/ecommerce'
import { fetchFromApi, parseJSONAPIPayload } from './fetchFromApi'
import { SplitTreatmentKey, SplitTreatmentResponse } from '@viewlio/types/api/juulio/SplitTreatmentResponse'

export const getStore = async (storeCode: string): Promise<JuulStore> =>
  fetchFromApi({
    relativePath: `/api/v1/stores/${storeCode.toLowerCase()}`,
  })

export const getProductCatalogue = async (
  locale?: string,
): Promise<ProductCatalogueRaw> => {
  const response = await fetchFromApi({
    locale,
    relativePath: '/api/v4/product_catalog',
  })

  return parseJSONAPIPayload<ProductCatalogueRaw>(response)
}

export const getShopper = async (): Promise<ShopperResponse> =>
  fetchFromApi({ relativePath: '/api/v1/shopper' })

export const updateShopper = async (body: Record<string, unknown>) => {
  fetchFromApi({
    body,
    method: 'PUT',
    relativePath: '/api/v1/shopper',
  })
}

export const getSplitTreatment =
  async (treatment: SplitTreatmentKey): Promise<SplitTreatmentResponse> =>
    fetchFromApi({
      relativePath: `/api/v1/split?features[]=${treatment}`,
    })

export const fetchCsrfToken = async (): Promise<CsrfTokenResponse> => (
  fetchFromApi({
    method: 'GET',
    relativePath: '/api/v4/csrf_token',
  })
)
