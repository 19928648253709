import { pathPatternsByRole } from './pathPatternsByRole'
import { paths } from './paths'
import { stores, ViewlioStoreConfig, ViewlioStore } from './stores'

export const viewlioConfig = {
  ageGate: {
    disabledPaths: [
      '/age-gate',
      '/geofence',
      '/tracking',
      '/youth-prevention',
      '/global',
      '/legal/intellectual-property-list',
      '/legal/global-privacy-notices',
      '/sso/signin',
      '/georestriction',
      '/report',
      '/report/*path',
      '/preview/*path',
      '/privacy/*path',
    ],
    oauthPermittedPaths: [
      '/signin',
      '/password/new',
      '/password/edit',
      '/password/security-question',
      '/signout',
      '/account/phone-verification',
      '/account/age-verification',
      '/account/age-verification/success',
      '/account/recovery',
      '/authentication/phone-verification',
      '/authentication/phone-verification/success',
      '/oauth/authorizations/cancel',
      '/legal/*path',
    ],
    // Compatibility with juulio (not all these exist in viewlio yet)
    // https://git.io/JDOZF and https://git.io/JDOZN
    warrantyPathPatterns: [
      '/claim-warranty{/*path}',
      '/signin',
      '/authentication{/*path}',
      '/account{/*path}',
      '/age_verification{/*path}',
    ],
  },
  ageVerification: {
    veriffUrl: 'https://magic.veriff.me/v/',
  },
  apiKeys: {
    adyen: {
      clientToken: process.env.NEXT_PUBLIC_ADYEN_CLIENT_TOKEN,
    },
    bugsnag: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    cloudfrontEdgeId: process.env.CLOUDFRONT_EDGE_ID,
    datadog: {
      applicationId: process.env.NEXT_PUBLIC_DATA_DOG_APP_ID,
      clientToken: process.env.NEXT_PUBLIC_DATA_DOG_CLIENT_TOKEN,
    },
    googleMaps: {
      clientToken: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
      mapId: 'c12d4f414b388bdb',
    },
  },
  autoship: {
    errorCodes: {
      hasExistingMembershipInCurrentStore: 1,
      hasExistingMembershipInDifferentStore: 6,
      newPlanExceedsMonthlyPackLimit: 3,
      newPlanExceedsMonthlyPodLimit: 4,
      wrongParameterForStoreLimit: 5,

    },
    taxons: {
      JUUL1: 'pods/juul-1',
      JUUL2: 'pods/juul-2',
    },
  },
  cacheTTL: {
    catalogue: 60000,
    juulioStore: 60000,
  },
  contentful: {
    environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENV,
    linkLevel: 10,
    preview: process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW,
  },
  cookieTTL: {
    anonymousId: 365,
  },
  cookies: {
    // These are pre-existing values, carried over from juulio
    aVCompletedLevel: 'AVCompletedLevel',
    accountRecovered: 'accountRecovered',
    ageGate: 'hasVerifiedAgeGate',
    cookieConsent: 'CookieConsent',
    countrySelector: 'stay_on_this_store',
    deliveryHintHasViewed: 'delivery_hint_has_viewed',
    deviceIsMobile: 'device_is_mobile',
    utmParams: 'utm_params',
    doNotTrackSegment: 'doNotTrackSegment',
    fromOauth: 'from_oauth',
    gdprAcknowledgement: 'hasAcknowledgedGdprSite',
    isAuthorizedBot: 'isAuthorizedBot',
    isRegisterAccountTabHidden: 'hide_account_registration_tab',
    isSignUpRequest: 'is_signup_request',
    juul2ComplexFlavorsEarlyAccess: 'juul_2_complex_flavors_early_access',
    juul2EarlyAccess: 'juul_2_early_access',
    juulAnonymousId: 'juul_anonymous_id',
    juulioUserGuid: 'juulio_user_guid',
    locatorAgeGate: 'hasVerifiedRouteToLocatorAgeGate',
    membershipConflictType: 'membershipConflictType',
    oauthSession: 'oauth_session',
    railsSession: '_juul_session',
    stateAbbr: 'state_abbr',
    userDeliverability: 'user_deliverability',
    warrantyAgeGate: 'hasVerifiedWarrantyAgeGate',
  },
  domains: [
    '$iso.dev.juul.com',
    '$iso.qa1.viewlio.app',
    '$iso.qa2.viewlio.app',
    '$iso.qa3.viewlio.app',
    '$iso.qa4.viewlio.app',
    '$iso.qa5.viewlio.app',
    '$iso.staging.viewlio.app',
    '$iso.stage.viewlio.app',
    '$iso.preprod.viewlio.app',
    '$iso.production.viewlio.app',
  ],
  environments: [
    'development',
    'ci',
    'preview',
    'staging',
    'preprod',
    'production',
    'qa1',
    'qa2',
    'qa3',
    'qa4',
    'qa5',
  ] as const,
  gitSHA:
    typeof process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA === 'string' ?
      process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA.substring(0, 7) :
      undefined
  ,
  imageParamMap: {
    'image/jpeg': {
      fl: 'progressive',
      fm: 'jpg',
      q: '80',
    },
    'image/png': {
      fm: 'png',
      q: '80',
    },
    'image/webp': {
      fm: 'webp',
      q: '70',
    },
  },
  lazyLoadOffset: 100,
  minLoadingDuration: 500,
  pathPatternsByRole,
  paths,
  railsSessionSecret: process.env.RAILS_SESSION_SECRET,
  revalidatePeriod: 600,
  rtlLanguageCodes: ['ar'],
  sdkKeys: {
    yoti: process.env.NEXT_PUBLIC_YOTI_SDK_KEY,
  },
  segment: {
    enabled: process.env.NEXT_PUBLIC_SEGMENT_ENABLED === 'true',
  },
  stores,
  testing: {
    mountedID: 'test-8e8a3f32-fa3b-4931-a704-fb44453a9b1b',
  },
  timers: {
    // The cart debounce is longer than userInputApiDebounceMs
    // as it can cause deadlock if updates are too frequent
    cartApiDebounceMs: 300,
    flashMessageTimeoutMs: 30000,
    notificationDurationMs: 2000,
    userInputApiDebounceMs: 300,
  },
  waf: {
    integrationUrl: process.env.NEXT_PUBLIC_WAF_INTEGRATION_URL,
  },
  xhrAbsoluteUrlEnvironments: [
    'ci',
    'development',
    'preview',
    'qa1',
    'qa2',
    'qa3',
    'qa4',
    'qa5',
  ],
}

export type { ViewlioStore } from './stores'
export type ViewlioConfig = typeof viewlioConfig
export type ViewlioEnv = typeof viewlioConfig.environments[number]
export type ViewlioEnvDeployed = Exclude<ViewlioEnv, 'development'>

/**
 * The cookie set by the OAuth server to determine settings and
 * layout for the auth flow.
 */
export type JuulioOauthSessionCookie = {
  isFromOauth: boolean
  isOauthSignUpRequest: boolean
  isRegisterAccountTabHidden: boolean
}

export const viewlioStores = Object.keys(viewlioConfig.stores) as ViewlioStore[]
export const viewlioLocales =
  Object.values(viewlioConfig.stores)
    .flatMap((store: ViewlioStoreConfig) => store.locales)

export const VIEWLIO_ENV: ViewlioEnv = viewlioConfig.environments.find(
  (env) => env === process.env.NEXT_PUBLIC_VIEWLIO_ENV,
) || 'development'

export const deployedEnvironments = viewlioConfig.environments
    .filter(env => !['development'].includes(env))
