import React, { useMemo } from 'react'
import Router from 'next/router'
import dynamic from 'next/dynamic'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { deployedEnvironments, viewlioConfig, VIEWLIO_ENV } from '@viewlio/config/viewlioConfig'
import { NavigationEntry } from '@viewlio/types/contentful'
import { datadogLogger } from 'lib/datadog'
import { paths } from '@viewlio/config/viewlioConfig/paths'

const SomethingWentWrong = dynamic(() =>
  import('components/common/ErrorPages/SomethingWentWrong')
    .then(mod => mod.SomethingWentWrong),
)

const { apiKeys, gitSHA } = viewlioConfig

export const bugsnagClient = Bugsnag.start({
  apiKey: apiKeys.bugsnag,
  appVersion: gitSHA,
  enabledReleaseStages: deployedEnvironments,
  logger: null,
  onError: (event) => {
    const { breadcrumbs, context, originalError } = event
    const { message } = originalError

    const reactError = message.match(/Minified React error #(?<code>.*);/)
    const chunkError = message.match(/Loading chunk \d+ failed/)

    if (chunkError && typeof window !== 'undefined') {
      // In production builds, the same error can legitimately trigger
      // this handler twice, to avoid redirect loops we use a global flag
      if (window._isHandlingChunkError) return;
      window._isHandlingChunkError = true;

      try {
        // We cannot use js-cookie here (even with typeof window checks)
        const parseCookies = () => {
          if (!document.cookie) {
            return {}
          }

          return Object.fromEntries(
            document.cookie.split('; ').map(cookie => {
              const [key, ...v] = cookie.split('=');
              return [key, v.join('=')];
            }),
          );
        }

        datadogLogger.info('Chunk load error', { breadcrumbs })
        const cookies = parseCookies()

        if (!cookies.chunkLoadReloadAttempted) {
          document.cookie = 'chunkLoadReloadAttempted=true; path=/; max-age=30';
          window.location.reload()
        } else {
          document.cookie = 'chunkLoadReloadAttempted=; path=/; expires=Thu, 01 Jan 1970 00:00:00 UTC';
          Router.push(paths.somethingWentWrong)
        }
      } finally {
        setTimeout(() => {
          window._isHandlingChunkError = false
        }, 1000)
      }
    }

    if (reactError) {
      const { code } = reactError.groups

      const knownHydrationError = ['423', '418'].includes(code)
      const knownHydrationErrorPath = /(signin|age-gate|shop)/.test(context)
      const shouldWarn = knownHydrationError && knownHydrationErrorPath

      event.errors[0].errorClass = 'ReactMinifiedError'
      event.severity = shouldWarn ? 'warning' : event.severity
      event.unhandled = !shouldWarn

      event.addMetadata('React Minified Error', {
        errorCode: reactError.groups?.code,
        knownHydrationError,
      })
    }

    if (window?.FS?.getCurrentSessionURL) {
      event.addMetadata('fullstory', {
        urlAtTime: window.FS.getCurrentSessionURL(true),
      })
    }
  },
  plugins: [new BugsnagPluginReact()],
  releaseStage: VIEWLIO_ENV,
})

type ErrorBoundaryProps = {
  navigationEntry: NavigationEntry
}

// This component factory fn required as we need both: (1) Pass our contentful
// entry (2) pass a component to FallbackComponent prop on Bsnag error boundary
// so that bugsnag can pass its own props (otherwise fallback never renders!)
const createFallbackComponent = (
  navigationEntry: any,
) => {
  // If/when we need bugsnag's `error` or `clearError`, receive
  // them here and pass them to SomethingWentWrong
  const Fallback = () =>
    <SomethingWentWrong navigationEntry={navigationEntry} />
  return Fallback
};

export const ErrorBoundary: React.FC<
  React.PropsWithChildren<ErrorBoundaryProps>
> = ({
  children,
  navigationEntry,
}) => {
  const BugsnagErrorBoundary = useMemo(() => {
    return Bugsnag.getPlugin('react')?.createErrorBoundary(React)
  }, [])

  const FallbackComponent = useMemo(
    () => createFallbackComponent(navigationEntry),
    [navigationEntry],
  )

  return (
    <BugsnagErrorBoundary FallbackComponent={FallbackComponent}>
      {children}
    </BugsnagErrorBoundary>
  )
}
