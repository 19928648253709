import React, { FC, PropsWithChildren } from 'react'

import { bugsnagClient } from 'lib/bugsnag'
import { Client } from '@bugsnag/core/types';

export const BugsnagContext = React.createContext<{
  bugsnagClient?: Client;
}>(null);

export const BugsnagContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const bugsnagContextValue = {
    bugsnagClient,
  }

  return (
    <BugsnagContext.Provider value={bugsnagContextValue}>
      {children}
    </BugsnagContext.Provider>
  )
}
