type Options = {
  arrayFormat?: 'brackets'
  encode?: boolean
  encodeValuesOnly?: boolean
}

type ArrayKeyFormatter = typeof getKeyFormatterForArrayFormat[Options['arrayFormat']]
type QuerystringValue = string | number | boolean
type EntryPair = [string, QuerystringValue]

export const parse = (string) => {
  if (!string.length) {
    return {}
  }

  // These querystring params where you have an '&' within a nested querystring
  // such as ?a=1&b=/shop?redirect=foo&bar=true is only allowed with 'encode'
  // option as true when stringifying. (Note: we are referring to the final '&')
  //
  // For example:
  //  * we KNOW where to split 'something=1&else=%2Ffoo%3Fbar%3D1%26baz%3D2'
  //  * we CANNOT KNOW where to split '?something=1&else=/foo?bar=1&baz=2'
  //
  // This is why we split by '&' before decoding
  // This is also true for the 'qs' npm package
  const pairs: string[][] = string.split('&')
    .map(ele => decodeURIComponent(ele))
    .map(ele => ele.split(/=(.*)/)) // split only by first '=' char

  const nonArrayKeyValues = []
  const arrayKeyValues = {}

  for (const [key, value] of pairs) {
    if (!key.endsWith('[]')) {
      // For non-array elements, we keep the key-value pair as-is
      nonArrayKeyValues.push([key, value ?? ''])
      continue
    }

    // For array elements, we combine e.g [['c[]', first], ['c[]', second]]
    // into { 'c': [first, second] }, which we later turn into a single pair
    const keyForArray = key.replace('[]', '')
    if (!arrayKeyValues[keyForArray]) {
      arrayKeyValues[keyForArray] = [value]
    } else {
      arrayKeyValues[keyForArray].push(value)
    }
  }

  return Object.fromEntries([
    ...nonArrayKeyValues,
    ...Object.entries(arrayKeyValues),
  ])
}

const getKeyFormatterForArrayFormat: Record<Options['arrayFormat'], (key: string) => string> = {
  // See https://github.com/ljharb/qs for commonly used array formats
  // Note: rails/juulio uses the 'brackets' format
  brackets: (key) => `${key}[]`,
}

const isObject = (data) =>
  typeof data === 'object' &&
  !Array.isArray(data) &&
  data !== null

const stringifyNestedObject = (
  key: string,
  value: QuerystringValue,
  result: Array<EntryPair>,
  arrayKeyFormatter: ArrayKeyFormatter,
) => {
  if (!isObject(value)) {
    // recursion base case
    if (Array.isArray(value)) {
      for (const arrValue of value) {
        result.push([arrayKeyFormatter(key), arrValue])
      }
    } else {
      result.push([key, value])
    }
  } else {
    return Object.entries(value).map(([k, v]) => {
      stringifyNestedObject(`${key}[${k}]`, v, result, arrayKeyFormatter)
    })
  }
}

export const stringify = (
  object: Record<string, any>,
  optionsOverrides: Options = {},
) => {
  // Get final options from (possibly) partial optionsOverrides
  const defaultOptions = {
    arrayFormat: 'brackets',
    encode: true,
  }
  const options = Object.assign(defaultOptions, optionsOverrides)

  // Result array
  const entries = new Array<EntryPair>()

  const arrayKeyFormatter = getKeyFormatterForArrayFormat[options.arrayFormat]

  Object.entries(object).map(([key, value]) => {
    if (Array.isArray(value)) {
      for (const arrValue of value) {
        entries.push([arrayKeyFormatter(key), arrValue])
      }
    } else {
      if (isObject(value)) {
        stringifyNestedObject(key, value, entries, arrayKeyFormatter)
      } else {
        entries.push([key, value])
      }
    }
  })

  // Combine all element pairs into a querystring joined by '&'
  // sorting of `entries` is not necessary but makes testing easier
  const finalEntries = []
  entries.sort().forEach(([key, value]) => {
    if (value === null || value === undefined) {
      value = ''
    }

    if (options.encodeValuesOnly) {
      finalEntries.push(`${key}=${encodeURIComponent(value)}`)
      return
    }

    if (options.encode) {
      finalEntries.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      return
    }

    finalEntries.push(`${key}=${value}`)
  })

  return finalEntries.join('&')
}
